<template>
  <div class="login-container" id="industrySectorScroll" :style="{height: screenHeight + 'px'}">
    <div class="login-f1">
      <div class="imgLogin" v-if="clientWidth <= 600"></div>
      <div class="imgLogins" v-else></div>
      <div class="iptContent">
        <div class="logo_father">
          <div class="logo_img"></div>
        </div>
        <div class="ipt_cs">
          <van-form @submit="onSubmit">
            <van-field
              v-model.trim="param.username"
              name="用户名"
              placeholder="用户名"
            />
            <van-field
              v-model.trim="param.password"
              type="password"
              name="密码"
              placeholder="密码"
            />
            <div
              style="
                text-align: left;
                margin: 10px 5px;
                display: inline-block;
                width: 45%;
                float: left;
              "
            >
              <span
                style="
                  color: white;
                  position: relative;
                  bottom: 10px;
                  text-decoration: underline;
                "
                @click="totalRegister"
                >立即注册</span
              >
            </div>
            <div
              style="
                text-align: right;
                margin: 10px 5px;
                display: inline-block;
                width: 45%;
                float: right;
              "
            >
              <span
                style="
                  color: white;
                  position: relative;
                  bottom: 10px;
                  text-decoration: underline;
                "
                @click="forgetPass"
                >忘记密码</span
              >
            </div>
            <div style="margin-bottom: 20%;">
              <van-button block type="info" native-type="submit"
                >登&nbsp;录</van-button
              >
            </div>
          </van-form>
        </div>
        <div class="footer_img" v-if="judegHeight">
          <div class="footer_i"></div>
        </div>
      </div>
    </div>
    <div class="copyright_cs">
      <p class="first_cs">
        <span style="color: #888; margin-top: 1px">ICP备案/许可证号: </span>
        <span style="color: #888; margin-top: 1px"
          ><a
            href="https://beian.miit.gov.cn/?token=94045190-da2b-42d0-a520-a4bab63af8f5#/Integrated/index"
            target="_black"
            style="color: #888"
            >【蜀ICP备2022028474号】</a
          ></span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
let telReg = /^1[3,4,5,6,7,8,9]{1}\d{9}$/;
let posswordReg = /^(\w){6,18}$/; //只能输入6-18位字母、数字、下划线
export default {
  data: function () {
    return {
      judegHeight: true,
      clientWidth:
        document.body.clientWidth || document.documentElement.clientWidth,
      passwordOff: true,
      screenHeight: 0,
      param: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    this.screenHeight = document.documentElement.clientHeight;
    if(this.screenHeight > 640){
        this.judegHeight = true;
    }else{
        this.judegHeight = false;
    }
    this.getCodeUrl();
    // 获取当前页面的url地址
    var test = window.location.href;
    // 截取url地址?后面的地址
    if(test.indexOf('UID') != -1){
      if (test.indexOf("?") != -1) {
        var testArr = test.split("?");
        // 再次截取后面的参数
        var UID = testArr[1].split("=");
        // var UID2 = UID1[1].split("&");
        // window.localStorage.setItem("UID", UID2[0] || "");

        if (
          UID[0] != null ||
          (UID[0] != undefined && UID[1] != "") ||
          UID[1] != null
        ) {
          if (UID[1].indexOf("#") || UID[1].indexOf("&")) {
            UID[1] = UID[1].replace("#", "");
            UID = UID[1].split("&");
          }
          window.localStorage.setItem("UID", UID[0] || "");
        }
      }
    }else{
      window.localStorage.setItem("UID", null);
    }


    if (
      localStorage.getItem("merchantId") != null ||
      localStorage.getItem("merchantId") != undefined
    ) {
      this.$cookies.set(
        "ms_username",
        localStorage.getItem("ms_username"),
        500 * 24 * 60 * 60
      );
    }
  },
  methods: {
    // 获取codeurl
    getCodeUrl(){
      
    },
    totalRegister() {
      this.$router.push("/register");
    },
    inputPhone() {
      if (this.param.username > 11) {
        this.param.username = this.param.username.slice(0, 11);
      }
    },
    onSubmit(values) {
      if (values) {
        var temp = null;
        if (localStorage.getItem("UID")) {
          temp = localStorage.getItem("UID");
        }
        // window.localStorage.clear();

        if (temp) {
          window.localStorage.setItem("UID", temp);
        }
        this.$store.commit("saveMerchantId", "");
        this.$store.commit("saveOperator", "");
        this.$store.commit("saveBranchId", "");
        this.$store.commit("saveServiceType", "");
        this.$store.commit("saveIsEfs", 0);
        this.$store.commit("saveResources", "");
        this.$cookies.remove("resources");
        this.$cookies.remove("ms_username");
        this.$cookies.remove("ms_phone");
        this.$cookies.remove("serviceType");
        this.$cookies.remove("isEfs");
        if (this.param.username == "") {
          Notify({ type: "danger", message: "用户名不能为空" });
          return false;
        } else if (this.param.password == "") {
          Notify({ type: "danger", message: "密码不能为空" });
          return false;
        } else {
          // if (!telReg.test(this.param.username)) {
          //   Notify({ type: 'danger', message: '手机号格式错误' })
          //   return false
          // }
          if (!posswordReg.test(this.param.password)) {
            Notify({
              type: "danger",
              message: "密码只能输入6-18位字母、数字、下划线",
            });
            return false;
          }
        }
        this.$http
          .ajax(
            "/ffkj-main/merchant/login",
            {
              phone: this.param.username,
              password: this.$md5(this.param.password),
              openId: window.localStorage.openId,
              code: null,
            },
            "post",
            {}
          )
          .then((res) => {
            if (res.code == 0) {
              localStorage.setItem("loginTime", new Date().getTime()); //重置时间
              // Notify({ type: "success", message: "登录成功" });

              // 1到家 2到店
              this.$cookies.set(
                "serviceType",
                res.data.serviceType,
                24 * 60 * 60
              );
              this.$store.commit("saveServiceType", res.data.serviceType);

              this.$store.commit("saveIsEfs", res.data.isEfs || 0);
              // this.$cookies.set("isEfs", res.data.isEfs || 0, 24 * 60 * 60);

              this.$store.commit("saveResources", res.data.resources || "");
              this.$cookies.set(
                "resources",
                res.data.resources || "",
                24 * 60 * 60
              );

              this.$cookies.set(
                "ms_username",
                (res.data.merchantName || "") +
                  " - " +
                  (res.data.contacts || ""),
                7 * 24 * 60 * 60
              );

              window.localStorage.setItem("operator", res.data.id || "");
              this.$store.commit("saveOperator", res.data.id || "");
              window.localStorage.setItem("branchId", res.data.branchId || "");
              this.$store.commit("saveBranchId", res.data.branchId || "");
              window.localStorage.setItem(
                "ms_username",
                (res.data.merchantName || "") +
                  " - " +
                  (res.data.contacts || "")
              );
              window.localStorage.setItem("merchantId", res.data.merchantId);
              this.$store.commit("merchantId", res.data.merchantId);
              this.$store.commit("saveMerchantId", res.data.merchantId);
              window.localStorage.setItem('ms_phone', res.data.phone || '');
              this.$cookies.set(
                "ms_phone",
                res.data.phone,
                7 * 24 * 60 * 60
              );
              Notify({ type: "success", message: '登录成功' });
              if (localStorage.getItem("UID") != 'null') {
                this.$router.push("/applyProductBasic");
              } else {
                this.$router.push("/productAudit");
              }
              // this.$router.push("/index");
            } else {
              Notify({ type: "danger", message: res.message });
            }
          });
      } else {
        Notify({ type: "danger", message: "请输入账号和密码" });
        return false;
      }
    },
    // 忘记密码
    forgetPass() {
      this.$router.push("/updatePass");
    },
  },
};
</script>

<style scoped>
</style>
